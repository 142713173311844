body, html {
  height: 100%;
  margin: 0;
}

.image-background {
  /* background-image: url("./assets/Landing.jpg"); */
  /* Full height */

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}


.row {
  display: flex;
  flex-direction: row;
}
.space-around {
  justify-content: space-around;
}
.space-between {
  justify-content: space-between;
}
.App-logo {
  height: 30vmin;
  /* background-color:  rgba(255, 255, 255, 0.3); */
  border-radius: 10px;
  pointer-events: none;
}
.logo-size {
  height: 6vmin;
  
  pointer-events: none;
}
.logo {
  height: 5vmin;
  pointer-events: none;
}
.margin-left-right-10 {
  margin-left: 10px;
  margin-right: 10px;
}
.text-align-center {
  text-align: center;
}
.font-bold {
  font-weight: 900;
}
.margin-top-150 {
  margin-top: 150px;
}
.color-white{
  color: white;
}
.margin-top-10 {
  margin-top: 10px;
}
.margin-vertical-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}
footer.page-footer .footer-copyright {
  bottom: 0;
  width: 100%;
  background: black;
  z-index:999;
}

.footer {
  width: 100%;
}

.text:hover {
  text-decoration: underline;
}
.horizontal-line {
  width: 100%; 
  text-align: center; 
  border-bottom: 1px solid #000; 
  line-height: 0.1em;
  margin: 20px 0 20px; 
}

.horizontal-line span { 
  background:#fff; 
  padding:0 10px; 
}
.coming-soon {
  color: rgb(128,0,0);
  /* text-align: center; */
}

.nav-link:hover {
  text-decoration: underline;
  
}

.card:hover {
  border-color: darkgray;
}
.app-card:hover {
  border-color: darkgray;
  background-color: rgb(166, 58, 103);
  opacity: 1.0;
}
.app-card > img {
  /* height: 100%; */
  height: 250px;
}
.card-image {
  background: '#000000';
}
.app-card > div { 
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;  
}

.app-card:hover > p {
  color: white;
  font-weight: bold;
}
.app-card:hover > a, a:link, a:visited  {
  font-weight: bold;
}

.collasible-nav-dropdown:hover {
  border-color: darkgray;
}

.dot {
  height: 70px;
  width: 70px;
  background-color: black;
  color: white;
  font-size: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar-hamburger {
  color: white;
}

ul {
  list-style-type: none;
}
.header-color {
  color: #5F6060;
}
.fill-button {
  color: white;
  background-color: #A63A67;
  border-color: #A63A67;
  padding-top: 3px;
  padding-bottom: 3px;
  font-size: 20px;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 10px;
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  /* margin-left: 10px; */
}
.fill-button:hover {
  color: #A63A67;
  background-color: white;
  border-color: #A63A67;
  align-items: center;
  display: flex;
  justify-content: center;
  
}
.fill-button span {
  font-size: 16px;
}
.blank-button {
  color: #A63A67;
  background-color: white;
  border-color: #A63A67;
  padding-top: 3px;
  padding-bottom: 3px;
  font-size: 20px;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 10px;
  margin-left: 10px;
}
.blank-button span {
  font-size: 16px;
}
.blank-button:hover {
  color: white;
  background-color: #A63A67;
  border-color: #A63A67;
  align-items: center;
  display: flex;
  justify-content: center;
}
.cart {
  height: 5vmin;
  margin-left: 10px;
  margin-right: 10px;
  pointer-events: none;
}

.wrapper {
  display: none;
  padding: 0 1.5em;
  background: white;
  border: 1px solid lightgray;
  border-radius: 1.5em;
  overflow: hidden;
  width: 400px;
}

.side-drawer {
  height: 100%;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 70%;
  z-index: 200;
  transform: translateX(-100%);
  transition: transform 0.3s ease-out;
}

.side-drawer.open {
  transform: translateX(0);
}

.wrapper:hover,
.focus {
  box-shadow: 0 1px 6px 0 rgba(32,33,36,0.28)
}
.search-bar {
  height: 3em;
  align-items: center;
}
.search-bar input[type=text] {
  width: 100%;
}
.margin-left-right {
  
  margin-right: 50px;
}
.top-option {
  margin-top: 70px;
}
@media (min-width: 600px) {
  .wrapper {
    display: flex;
  }
  /* .search-bar {
    padding-right: 400px;
  } */
  .top-option {
    margin-top: 120px;
  }

}
input:focus {
  outline:none;
}
:focus {
  outline: none;
}
.list {
  list-style: none;
  margin: 0;
  padding: 0;
  padding-bottom: 1.5em;
  border-top: 1px solid lightgray;
  max-height: 0;
  overflow: hidden;
  overflow-y: auto;
  animation: enlarge .25s forwards;
}
.result {
  display: flex;
  align-items: center;
}
@keyframes enlarge {
  to {
    max-height: 10em;
  }
}

.horizontal-line {
  width: 100%;
  text-align: center;
  border-bottom: 0.5px solid ;
  border-color: #707070;
  line-height: 0.1em;
  margin: 10px 0 20px;
}
.horizontal-line span {
  padding: 0 10px;
}
.footer-color {
  background: var(--unnamed-color-e4e4e4) 0% 0% no-repeat padding-box;
  background: #E4E4E4 0% 0% no-repeat padding-box;
  box-shadow: 0px 6px 40px #00000029;
  opacity: 1;
}
.subheading {
  margin-left: 20px;
  margin-bottom: 20px;
}
.popular-selection {
  font: Regular var(--unnamed-font-size-35)/var(--unnamed-line-spacing-71) var(--unnamed-font-family-segoe-ui);
  letter-spacing: var(--unnamed-character-spacing-0);
  text-align: left;
  font: Regular 35px/71px Segoe UI;
  letter-spacing: 0px;
  color: #000000;
  font-size: 22px;
  opacity: 1;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 20px;
}
.store-wedlite {
  text-align: left;
  font: Regular 45px/71px Segoe UI;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}
.align-center {
  display: flex;
  flex: 1;
  justify-content: center;
}

.app-card:hover > .title-shop {
  opacity: 1; 
}
.card-image:hover  {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.app-card:hover .lazy-load-image-background.blur.lazy-load-image-loaded {
  opacity: 0.4;
  color: rgba(166, 58, 103, 0.5); 
}

.app-card > .title-shop {
  opacity: 0;
}

.common-fill-button {
  color: #A63A67;
  background-color: white;
  border-color: white;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
  font-size: 12px;
  border-radius: 10px;
  margin-left: 10px;
}

.search-anything:hover {
  background-color: #A63A67;
  color: white;

}

.overflow-scroll {
  overflow: scroll;
}


@media (min-width: 600px) {  
  .img-landing {
    position: absolute;
  }
  .carousel-caption {
    width: 100%; 
  }
  .carousel-search-bar {
    display: none;
  }
  
  .carousel-absolute {
    /* display: none; */
    position: absolute;
    right: 0;
    margin-top: 100px;
    margin-right: 100px;

  }
}

.border-solid {
  border-radius: 10px;
  border-style: solid;
  border-color: #707070;
  border-width: thin;
  padding-right: 10px;  
}

@media (max-width: 600px) { 
  .carousel-absolute {
    display: none;
  }
  .carousel-search-bar {
    width: 100%; 
    left: 0
  } 
  
   .middle {
    transition: .5s ease;
    position: relative;
    
    text-align: center;
    z-index: 999;
  }
  
  /* .carousel-search-bar {
    width: 100%;  */
    /* position: absolute;
    left: 0; 
    top: 20; */
  /* } */

}

.app-card {
  flex: 0 0 auto;
  width: 12rem; 
  height: 150px;
  border-radius: 10px;
  margin-right: 1rem;
  /* margin-right: 1rem; */
}

.app-card .card-image {
  flex: 0 0 auto;
  width: 12rem; 
  height: 150px;
  
  /* height: 100vh; */
}

@media (min-width: 600px) {  
  .app-card {
    flex: 0 0 auto;
    width: 20rem; 
    height: 240px;
    margin-right: 1rem;
  }
  .app-card .card-image {
    flex: 0 0 auto;
    flex: 1;
    width: 20rem; 
    height: 240px;
  }
  .shop-home {
    justify-content: space-around;
    
  }
  
}
.shop-home {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  flex: 0 0 auto;
  padding-left: 20px;
  padding-right: 50px;
}

.shop-home::-webkit-scrollbar {    display: none;}

.category  {
  flex: 0 0 auto;
  width: 16rem; 
  margin-right: 1rem;
  margin-left: 5px;
  
}
.category {
  flex: 0 0 auto;
  width: 16rem;
  margin-right: 1rem;
}
.category-card-body {
  font-size: 12px;
}
.category-card-image {
  width: 16rem;
  height: 140px;
}
.image-cart {
  height: 200px;
  width: 100%; 
  border-radius: 20px; 
  margin-right: 20px;
}

.cart-container {
  flex-direction: column;
}

.width-container {
  width: 100%;
}
.cart-items-container {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
}

.width-container-place-order {
  width: 100%;

}

@media (min-width: 600px) {  
  .home-container {
    margin: 0px 40px 0px 40px;
  }
  
  .category {
    flex: 0 0 auto;
    width: 20rem; 
    margin-right: 1rem;
  }
  .category-card-image {
    width: 20rem;
    height: 160px;
  }
  .category-card-body {
    font-size: 16px;
  }

  .search-bar-background div {
    display: flex;
    flex-direction: column;
  }
  .image-cart {
    height: 100px;
    width: 100px; 
  }
  .cart-container {
    flex-direction: row;
  }
  .width-container {
    width: 80%;
  }
  .width-container-place-order {
    width: 20%;
  }
 
  .middle {
    transition: .5s ease;
    
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
    z-index: 999;
  }
  
}


.font-size-label {
  text-align: left;
  font-size: 8px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  
}

.box {
  width: 50%;
  height: auto;
  max-height: 50em;
  align-items: center;
  display:block;
  margin:auto;
}


.submitted-text {
  text-align: center;

  font: normal normal 600 20px/30px Segoe UI;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}
.invitation-dashboard {
  max-width: 70%; 
}