.font-size-label {
  font-size: 18px;
  
}

.search-box:focus {
  outline: none !important;
  border:1px solid red;
  box-shadow: 0 0 10px #719ECE;
}

.border {
  padding: 20px;
  box-shadow: 1px 1px 1px 1px rgba(128, 128, 128, 0.5);
}
.border-container {
  width: 100%;
}

.login-logo {
  height: 12vmin
}

@media (min-width: 600px) {
  .border-container {
    width: 40%;
  } 
  .login-logo {
    height: 8vmin
  }
}
