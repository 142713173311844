.searchbar {
  width: 30%;   
}

.searchbar-container {
  margin-top: 10px;
}
input[type=text], select {
  padding: 7.5px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;

  box-sizing: border-box;
}

input[type=submit] {
  background-color: #4CAF50;
  color: white;
  padding: 10px 18px;
  margin: 8px 0;
  border: none;

  cursor: pointer;
}

::-webkit-input-placeholder {
  font-size: 17px;
}

input[type=submit]:hover {
  background-color: #45a049;
}

.search-button {
  padding: 7.5px 20px;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  background-color: #55BDD6;
  color: white;
  width: 140px;
  font-size: 12px;
}
.button {
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  background-color: #55BDD6;
  border: none;
  color: #FFFFFF;
  text-align: center;
  font-size: 14px;
  padding: 11px 20px;
  width: 140px;
  transition: all 0.5s;
  cursor: pointer;
}

.button span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.button span:after {
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.button:hover span {
  padding-right: 25px;
}

.button:hover span:after {
  opacity: 1;
  right: 0;
}
.align-items-center {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.ui.dropdown.search{
  border-top-left-radius: 10;
  border-bottom-left-radius: 10;
  border-top-right-radius: 10;
  border-bottom-right-radius: 10;
  align-items: center;
  color: black;
}

.dropdown {
  min-height: auto !important;
  height: 3.0em;
  height: 2.5em;
  width: 1em;
  border-radius: 10px;
  margin-left: 5px;
  max-width: 100px;
}

.tagline {
  font: Italic var(--unnamed-font-size-40)/var(--unnamed-line-spacing-53) var(--unnamed-font-family-segoe-ui);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-a63a67);
  /* text-align: center; */
  font: Italic 15px/33px Segoe UI;
  letter-spacing: 0px;
  color: #A63A67;
  opacity: 1;
}
.tagline-info {
  /* font: Regular var(--unnamed-font-size-25)/var(--unnamed-line-spacing-33) var(--unnamed-font-family-segoe-ui); */
  letter-spacing: var(--unnamed-character-spacing-0);
  /* text-align: center; */
  color: var(--unnamed-color-000000);
  /* font: Regular 20px/40px Segoe UI; */
  font: normal normal normal 21px/28px Segoe UI;
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.fill-button-let-begin {
  color: white;
  background-color: #A63A67;
  border-color: #A63A67;
  padding-top: 3px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 3px;
  font-size: 14px;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 10px;
  margin-left: 10px;
}

/* Pattern styles */
.container {
  display: flex;
}

.left-half {
  background-color: #ff9e2c;
  flex: 1;
  padding: 1rem;
}

.right-half {
  background-color: #b6701e;
  flex: 1;
  padding: 1rem;
}

.ui.dropdown.search{
  /* min-width: 100px; */
  
  border-radius: 10px;
  align-items: center;
  min-width: 150px;
}

@media (max-width: 600px) {  
  .ui.dropdown.search{
    /* min-width: 100px; */
    min-width: 80px;
    font-size: 10px;
    border-radius: 10px;
    align-items: center;
    
  }
  
}



@media (min-width: 600px) {  
  .bar-tab {
    align-items: center;
  }
  .image-background  {
    height: 100vh;
    flex: 1;
    justify-content: center;
  }
  .form-search {
    align-items: center;
    /* position: absolute; */
    width: 43%;
    /* height: 800px; */

  }
  .tagline {
    padding-left: 30px;
    font: Italic 30px/53px Segoe UI;
  }
  .tagline-info {
    font-size: 20px;
    text-align: center;
  }
  
}
.tagline {
  font: italic normal 600 20px/35px Segoe UI;
}
.top-tagline {
  
  
  font: normal normal normal 33px/44px Segoe UI;
  letter-spacing: 0px;
  color: #A63A67;
  opacity: 1;
}

.wedding-invitation {
  letter-spacing: 0px;
  color: #A63A67;
  opacity: 1;
}
.scan-qr-code {
  margin-top: 30px;
  text-align: center;
  font: normal normal normal 17px/24px Segoe UI;
  letter-spacing: 0.26px;
  color: #A63A67;
  opacity: 1;
}
.icon-outer {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 12px #00000021;
  opacity: 1;
  border-radius: 30px;
  padding: 10px;
  margin: 0px 0px 10px 0px;
  /* margin-right: 10px; */
}
.icon-container {
  margin: 10px 20px 20px 0px;
}
.text-wedding {
  text-align: center;
  font: normal normal normal 10px/10px Segoe UI;
  letter-spacing: 0.21px;
  color: #A63A67;
  opacity: 1;
}