.image {
  height: 500px; /* You must set a specified height */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; 
  padding-top: 20px; 
}

.carousel .thumb img {
  width: 100% !important;
  height: 100% !important;
}

.carousel .slide img {
  max-height: 300px;  /* change this to whatever you want */
  width: auto;
}

.container {
  width: 70%;
  background-color: white;
}

.felx-container {
  display: flex;
  justify-content: center;
}
.carousel .slide{ background-color: none; }

.price {
  text-align: left;
  font: Semibold 40px/64px Segoe UI;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}
.common-size-text {
  text-align: left;
  font: Regular 25px/27px Segoe UI;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-size: 1.3em;
}

.error {
  border: red 2.7px solid;
}
.pincodeContainer {
  width: 100px;
}
.districtContainer {
  width: 100px;
}
.stateContainer {
  width: 100px;
}
