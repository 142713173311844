.carousel .slide{ background-color: none; }

.price {
  text-align: left;
  font: Semibold 40px/64px Segoe UI;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}
.common-size-text {
  text-align: left;
  font: Regular 25px/27px Segoe UI;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-size: 1.3em;
}

.error {
  border: red 2.7px solid;
}
.pincodeContainer {
  width: 100px;
}
.districtContainer {
  width: 100px;
}
.stateContainer {
  width: 100px;
}

.carousel .slide img {
  max-height: 500px;  /* change this to whatever you want */
  width: auto;
}

.single-item-shop {
  flex-direction: column;
}

.single-shop-container {
  margin-left: 0px;
  margin-right: 0px;
}
@media (min-width: 600px) {  
  .single-item-shop {
    flex-direction: row;
  }
  .single-shop-container {
    margin-left: 200px;
    margin-right: 200px;
  }
}