/* @import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700,900&display=swap');
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700&display=swap'); */


* {
  margin: 0px;
  outline: none;
  padding: 0px;
  text-decoration: none;
}
.font-icon:hover {
  background-color: pink;
  padding: 1px;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-auto-rows: 364px;
  align-content: stretch;
  column-gap: 15px;
  row-gap: 15px;
  padding: 10px 25px;
  margin-top: 40px;
  margin-bottom: 40px;
}

.title {
  text-align: center;
  font-size: 18px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  display: none;
}


.item-title {
  text-align: left;
  font-size: 20px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.item-desc {
  text-align: left;
  font-size: 15px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.order-button {
  text-align: center;
  padding: 4px;
  /* border: 1px solid #000000; */
  border-radius: 5px;
  opacity: 1;
  width: 81px;
  height: 22px; 
}

.grid-item {
  padding-left: 30px;
  position: relative;
  background: transparent linear-gradient(180deg, #FFCECE 0%, #ECEAEA 100%) 0% 0% no-repeat padding-box;
  opacity: 1;
  grid-row: 2;
}

.grid-item-1{
  grid-column: 1 / span 2;
  grid-row: 1;
}

.grid-item > img {

  flex: 1;
  
}

.grid-item-3{
  background: transparent linear-gradient(128deg, #E35C0DA8 0%, #A63A67AB 100%) 0% 0% no-repeat padding-box;
  opacity: 1;
  grid-row: 2;
}



.grid-item-3 > img {
  position: absolute;
  top: 20px;
  right: 40px;
}

@media (max-width: 600px){
  h1{
    font-size: 30px !important;
  }
  .home-cont1-text {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
}

.card-category {
  display: flex;
  flex-direction: column;  
  padding-bottom: 10px;
  
}

.image-category {
  height: 300px;
}

.image-category:hover > img {
  border-radius: 0px;
}


.img-category {
  height: 120px;
  width: 120px
}

.card-category:hover  {
  border: 1px solid gray;  
}

.logo-show {
  display: none;
}

.card-category {
  flex-direction: row;
  align-items: center; 
  flex: 1;
}
.products-category .card-category h2 {
  color: #A63A67; 
  cursor: pointer;
}

.products-category .card-category span {
  font-weight: bold;
}

.products-category .card-category .image-category{
  width: 12rem; 
  height: 150px;
  border-radius: 10px;
}

@media (min-width: 600px) {  
  .products-category {
    flex-direction: row;
    justify-content: space-around;
    cursor: pointer;
  }
  .title {
    display: flex;
  }
  .logo-show {
    display: flex;
  }
  .card-category {
    flex-direction: column;
    /* width: 20em; */
  }
  .products-category .card-category .image-category {
    width: 20em;
    height: 250px;
    border-radius: 10px;
  }
  .img-category {
    height: 500px;
    width: 400px
  }
  
}