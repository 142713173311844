.row {
  display: flex;
  flex-direction: row;
}
.space-around {
  justify-content: space-around;
}
.space-between {
  justify-content: space-between;
}
.see-more-button {
  border: none;
  display: inline-block;
  background-color: rgba(51,51,51,0.75);
  color: #fff;
  margin: 4px 0px;
  padding: 0px 32px;
  box-sizing: border-box;
  height: 48px;
  border-radius: 24px;
}