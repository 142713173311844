.logo-container  {
  justify-content: center;
}
.logo {
  height: 10vmin;
  align-items: center;
  pointer-events: none;
}

.childrenDiv {
  padding-bottom: '14rem';
}

@media (max-width: 600px) {
  .childrenDiv {
    padding-bottom: '30rem';
  }

}